//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    modalName: {
      type: String,
      default: "addTeam",
    },
    editData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ruleForm: {
        teamId: "",
        name: null,
        describe: null,
        IsAgree: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入团队名称", trigger: "blur" }],
        IsAgree: [
          {
            required: true,
            message: "请选择是否允许成员邀请其他人加入团队",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    opened() {
      this.$nextTick(() => {
        if (this.modalName == "editTeam" && this.editData) {
          this.ruleForm.teamId = this.editData.Id;
          this.ruleForm.name = this.editData.Name;
          this.ruleForm.describe = this.editData.Describe;
          this.ruleForm.IsAgree = this.editData.IsAgree;
        }
      });
    },
    closed() {
      Object.assign(this.$data, this.$options.data());
    },
    onConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$refs.modal.loadBtn(true);
          this.$http
            .post("/Teams/SaveTeamManagement.ashx", this.ruleForm)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "团队创建成功",
                  type: "success",
                });
                this.$modal.hide(this.modalName);
                this.$emit("success", resp.data.teamId);
              }
            })
            .finally(() => this.$refs.modal.loadBtn(false));
        } else {
          return;
        }
      });
    },
  },
};
